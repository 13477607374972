import {
    QueryClient,
    setupStakingExtension,
    setupGovExtension,
    setupBankExtension,
    SigningStargateClient,
} from '@cosmjs/stargate';
import { OfflineSigner } from '@cosmjs/proto-signing';
import { HttpClient, Tendermint34Client } from '@cosmjs/tendermint-rpc';

export const TmClient = async (url: string): Promise<Tendermint34Client> => {
    const rpcClient = new HttpClient(url);
    const tmClient = await Tendermint34Client.create(rpcClient);

    return tmClient;
};

export const customQueryClient = async (url: string) => {
    const tmClient = await TmClient(url);
    const queryClient = new QueryClient(tmClient);

    return queryClient;
};

export const Staking = async (url: string, operatorAddress: string) => {
    const queryClient = await customQueryClient(url);
    const stakingExtension = setupStakingExtension(queryClient);
    const res = await stakingExtension.staking.validator(operatorAddress || '');

    return res;
};

export const Delegations = async (url: string, operatorAddress: string) => {
    const queryClient = await customQueryClient(url);
    const stakingExtension = setupStakingExtension(queryClient);
    const res = await stakingExtension.staking.delegatorDelegations(
        operatorAddress || '',
    );

    return res;
};

export const StakingPool = async (url: string) => {
    const queryClient = await customQueryClient(url);
    const stakingExtension = setupStakingExtension(queryClient);
    const res = await stakingExtension.staking.pool();

    return res;
};

export const Governance = async (url: string) => {
    const queryClient = await customQueryClient(url);
    const govExtension = setupGovExtension(queryClient);

    return govExtension;
};

export const Bank = async (url: string) => {
    const queryClient = await customQueryClient(url);
    const bankExtension = setupBankExtension(queryClient);

    return bankExtension;
};

export const stargateClient = async (
    rpcUrl: string,
    offlineSigner: OfflineSigner,
): Promise<SigningStargateClient> => {
    const client = await SigningStargateClient.connectWithSigner(
        rpcUrl,
        offlineSigner,
    );

    return client;
};
