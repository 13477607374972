import Box from '@material-ui/core/Box';
import { css } from '@emotion/react';
import { TextTitle, Text, Sheet } from 'Components';
import { ProposalCard } from './ProposalCard';
import swirl1 from '../Assets/swirl1.png';
import { useProposals } from './useProposals';
import { Grid } from 'react-loading-icons';

export const Proposals = (): JSX.Element => {
    const { data, isLoading, status, isError } = useProposals();
    const foo = [];
    return (
        <Sheet>
            <Sheet.body css={{ position: 'relative', minHeight: '100px' }}>
                <img
                    src={swirl1}
                    css={{ position: 'absolute', top: -20, right: -20 }}
                />
                <Text.md variant='accent'>Active Proposals</Text.md>
                <br />
                <br />
                {isLoading ? (
                    <div css={{ textAlign: 'center' }}>
                        <Grid height='48px' />
                    </div>
                ) : (
                    <div
                        css={{
                            minWidth: '600px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            '> div': {
                                margin: '1rem 0',
                            },
                            position: 'relative',
                        }}
                    >
                        {data?.length
                            ? data?.map((datum) => (
                                  <ProposalCard stats={datum} key={datum?.id} />
                              ))
                            : 'No Open proposals'}
                    </div>
                )}
            </Sheet.body>
        </Sheet>
    );
};
