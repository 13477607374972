import { Coin, StdFee } from '@cosmjs/stargate';
import { stargateClient } from 'Libs/cosmosClients';

export const delegateTokens = async (
    endpointUrl: string,
    offlineSigner: any,
    senderAddress: string,
    operatorAddress: string,
    amount: Coin,
    fee: StdFee,
    memo?: string,
): Promise<any> => {
    const client = await stargateClient(endpointUrl, offlineSigner);

    const res = await client.delegateTokens(
        senderAddress,
        operatorAddress,
        amount,
        fee,
        memo || '',
    );

    return res;
};
