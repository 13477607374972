import React, { useState } from 'react';
import { Button, Text } from 'Components';
import BigNumber from 'bignumber.js';
import { Coin } from '@cosmjs/stargate';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';

export const DelegateDialog = ({
    open,
    maxBalance,
    operatorAddress,
    onClose,
    onConfirm,
}: {
    open: boolean;
    maxBalance: Coin;
    operatorAddress: string;
    onClose: () => void;
    onConfirm: (address: string, amount: string) => Promise<void>;
}) => {
    const [amount, setAmount] = useState('');
    const [error, setError] = useState<string | null>(null);
    const handleAmountChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev?.target?.value || '';
        setAmount(value);
        if (!value || !parseInt(value)) {
            setError('Invalid Input');
            return;
        }
        const valueInBN = new BigNumber(value);
        valueInBN.lte(new BigNumber(maxBalance?.amount || ''))
            ? setError(null)
            : setError('Insufficient balance');
    };

    const handleConfirm = async () => {
        if (error) return;
        await onConfirm(operatorAddress, amount);
        setError(null);
        setAmount('');
        onClose();
    };

    const handleClose = () => {
        setAmount('');
        setError(null);
        onClose();
    };
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        background: 'var(--bgGradientTo)',
                        minWidth: '300px',
                    },
                }}
            >
                <DialogTitle css={{ color: 'var(--facePrimary)' }}>
                    <Text.title>{'Enter Amount in USOMM'}</Text.title>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        <input
                            type='text'
                            value={amount}
                            onChange={handleAmountChange}
                            css={{
                                background: 'var(--bgDefault)',
                                border: '1px solid var(--borderSecondary)',
                                color: 'var(--faceStrong)',
                                padding: '12px',
                                borderRadius: '4px',
                                '&:focus': {
                                    outline: 'none',
                                },
                            }}
                        />
                        &nbsp;&nbsp;
                        <Button
                            variant='transparent'
                            onClick={() => {
                                const b = new BigNumber(maxBalance?.amount);
                                // account for gas
                                const d = b.minus('500000');
                                setAmount(d.toString());
                            }}
                        >
                            Max
                        </Button>
                        <br />
                        {error ? (
                            <span>
                                <Text.sm variant='negative'>{error}</Text.sm>
                            </span>
                        ) : (
                            <span>
                                <Text.sm variant='accent'>
                                    {'1 SOMM equals 10^6 USOMM'}
                                </Text.sm>
                            </span>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='negative' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant='primary' onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
