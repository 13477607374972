import { useQuery } from 'react-query';
// import { TallyResult } from 'cosmjs-types/cosmos/gov/v1beta1/gov';

// export interface TallyResult {
//     [yes: string]: string;
//     [abstain: string]: string;
//     [no: string]: string;
//     [noWithVeto: string]: string;
// }

export interface TallyResult {
    [key: string]: string;
}
export interface UnMarshalledProposal {
    id: string;
    type: string;
    typeData: any;
    denom: string;
    status: string;
    totalDeposit: string;
    finalTallyResult?: TallyResult;
    submitTime: string;
    depositEndTime: string;
    votingStart: string;
    votingEnd: string;
}

type ProposalsResponse = {
    data: Array<UnMarshalledProposal> | undefined;
    isLoading: boolean;
    status: string;
    isError: boolean;
};

export const useProposals = (): ProposalsResponse => {
    const getValidators = async () => {
        const response = await fetch(
            `https://us-central1-peggyjv-sommscan-65139.cloudfunctions.net/getProposals`,
        );
        if (!response.ok) throw new Error(`Failed to fetch validators`);

        const data = await (response.json() as Promise<UnMarshalledProposal[]>);

        return data;
    };

    const { data, isLoading, status, isError } = useQuery(
        ['getValidators'],
        getValidators,
    );

    return { data, isLoading, status, isError };
};
