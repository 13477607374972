import { Sheet, Text, Button } from 'Components';
import { Box } from '@material-ui/core';
import { toUSD } from 'Components/Text/formats';
import { ProgressBar } from 'Cellars/ProgressBar';
import { UnMarshalledProposal } from 'Governance/useProposals';
import { AxisOptions, Chart } from 'react-charts';
import { useMemo } from 'react';
import React from 'react';
import { TallyResult } from './useProposals';

const TallyResultsBar = ({ tallyResult }: { tallyResult: TallyResult }) => {
    // const tallyData: Record<string, string> = {
    //     abstain: '398',
    //     no: '230',
    //     yes: '730',
    //     noWithVeto: '122',
    // };
    const data = Object.keys(tallyResult).map((key) => ({
        label: key,
        data: [{ group: 'Tally', count: parseInt(tallyResult?.[key]) }],
    }));
    console.log(data);
    const primaryAxis = useMemo<
        AxisOptions<typeof data[number]['data'][number]>
    >(
        () => ({
            position: 'left',
            show: false,
            getValue: (datum) => datum?.group,
        }),
        [],
    );

    const secondaryAxes = useMemo<
        AxisOptions<typeof data[number]['data'][number]>[]
    >(
        () => [
            {
                position: 'bottom',
                show: false,
                getValue: (datum) => datum?.count,
                stacked: true,
            },
        ],
        [],
    );
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                defaultColors: [
                    'var(--objAccentHover)',
                    'var(--objWarnHover)',
                    'var(--objPositiveHover)',
                    'var(--objNegativeHover)',
                ],
            }}
        />
    );
};

type CellarCardProps = {
    stats: UnMarshalledProposal;
};
export const ProposalCard = ({ stats }: CellarCardProps): JSX.Element => {
    console.log(stats);
    return (
        <>
            <Sheet
                css={{
                    width: '100%',
                    background: 'var(--objSecondary)',
                    backdropFilter: 'blur(16px)',
                }}
            >
                <Sheet.header>
                    <Box
                        display='flex'
                        alignItems='flex-start'
                        justifyContent='space-between'
                    >
                        <Box
                            css={{
                                marginRight: '0.5rem',
                            }}
                        >
                            <Box display='flex' flexDirection='column'>
                                <Text.md variant='strong'>
                                    {stats?.id} {stats?.typeData?.title}
                                </Text.md>
                            </Box>
                        </Box>
                        <Box>
                            <Box
                                css={{
                                    background: 'var(--objAccent)',
                                    border: '1px solid var(--borderAccent)',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Text.md variant='strong'>
                                    {stats?.status}
                                </Text.md>
                            </Box>
                        </Box>
                    </Box>
                </Sheet.header>
                <Sheet.body>
                    <Box>
                        <Text.p preview={true}>
                            {stats?.typeData?.description}
                        </Text.p>
                    </Box>
                    <Box css={{ height: '50px' }}>
                        {stats?.finalTallyResult && (
                            <TallyResultsBar
                                tallyResult={stats?.finalTallyResult}
                            />
                        )}
                    </Box>
                </Sheet.body>
                <Sheet.footer>
                    <Button variant='default' display='block' size='medium'>
                        {'Vote Coming Soon'}
                    </Button>
                </Sheet.footer>
            </Sheet>
        </>
    );
};
