import { useEffect, useState } from 'react';
import './App.scss';
import { Header, Footer, Sheet } from 'Components';
import { Cellars } from 'Cellars/Cellars';
import BigNumber from 'bignumber.js';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import { Validators } from 'Validators/Validators';
import { Proposals } from 'Governance/Proposals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { css } from '@emotion/react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Coin } from '@cosmjs/stargate';
import { Bank, Delegations } from 'Libs/cosmosClients';
import { transformOperAddress } from 'Libs/cosmosHelpers';
import { Window as KeplrWindow } from '@keplr-wallet/types';
import { delegateTokens } from 'Validators/delegateTokens';
import { SigningStargateClient } from '@cosmjs/stargate';
import { textSpanContainsTextSpan } from 'typescript';
import 'react-toastify/dist/ReactToastify.css';
declare global {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Window extends KeplrWindow {}
}

const chainId = 'sommelier-3';
const endpointUrl = 'https://sommelier.technofractal.com:443';
const denom = 'usomm';
const chainConfig = {
    chainId: 'sommelier-3',
    chainName: 'Sommelier',
    rpc: 'https://sommelier.technofractal.com:443',
    rest: 'https://lcd.sommelier.technofractal.com:443',
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: 'somm',
        bech32PrefixAccPub: 'somm' + 'pub',
        bech32PrefixValAddr: 'somm' + 'valoper',
        bech32PrefixValPub: 'somm' + 'valoperpub',
        bech32PrefixConsAddr: 'somm' + 'valcons',
        bech32PrefixConsPub: 'somm' + 'valconspub',
    },
    currencies: [
        {
            coinDenom: 'SOMM',
            coinMinimalDenom: 'usomm',
            coinDecimals: 6,
            coinGeckoId: 'sommelier',
        },
    ],
    feeCurrencies: [
        {
            coinDenom: 'SOMM',
            coinMinimalDenom: 'usomm',
            coinDecimals: 6,
            coinGeckoId: 'sommelier',
        },
    ],
    stakeCurrency: {
        coinDenom: 'SOMM',
        coinMinimalDenom: 'usomm',
        coinDecimals: 6,
        coinGeckoId: 'sommelier',
    },
    coinType: 118,
    gasPriceStep: {
        low: 0.0,
        average: 0.0,
        high: 0.03,
    },
};

function App(): JSX.Element {
    const queryClient = new QueryClient();

    const [balance, setBalance] = useState<Coin>({ amount: '', denom: '' });
    const [account, setAccount] = useState<string>('');
    const [signer, setSigner] = useState<any | null>(null);

    const accountHasTokens = parseFloat(balance?.amount) !== 0;

    // useEffect(() => {
    //     const operatorAddress = transformOperAddress(
    //         'sommvaloper1lexs4myxfp7k6n685qp6tw6mddkr2wetwddm2p',{}
    //     );
    //     console.log('operator address :: ', operatorAddress);
    //     setOperatorAddress(operatorAddress);
    // });

    useEffect(() => {
        const cb = (msg: string | null) => {
            console.warn(msg);
        };
        async function initializeWallet() {
            if (!(window as any).keplr) {
                const error = 'Please install keplr extension';
                cb(error);
            } else {
                if ((window as any).keplr.experimentalSuggestChain) {
                    try {
                        await (window as any)?.keplr.experimentalSuggestChain(
                            chainConfig,
                        );
                    } catch (error) {
                        const chainError = 'Failed to suggest the chain';
                        cb(chainError);
                    }
                } else {
                    const versionError =
                        'Please use the recent version of keplr extension';
                    cb(versionError);
                }

                await window?.keplr?.enable(chainId);
                (window as any).keplr.defaultOptions = {
                    sign: {
                        preferNoSetMemo: true,
                        preferNoSetFee: false,
                    },
                };
                const signer = (window as any).getOfflineSigner(chainId);
                signer.signAmino = signer.signAmino ?? signer.sign;
                setSigner(signer);
                const accounts = await signer.getAccounts();
                console.log('Connection :: ', chainId);
                // console.log('accounts ', accounts);
                // console.log('signer ', signer);

                const address = accounts[0]?.address;
                setAccount(address);
            }
        }

        void initializeWallet();

        document.addEventListener(
            'readystatechange',
            () => void initializeWallet(),
        );

        return () => {
            document.removeEventListener(
                'readystatechange',
                () => void initializeWallet(),
            );
        };
    }, []);

    useEffect(() => {
        document.body.classList.add('brand');
    });

    const getBalance = async () => {
        const bank = await Bank(endpointUrl);
        const balance = await bank.bank.balance(account, denom);
        setBalance(balance);
    };

    useEffect(() => {
        const checkBalance = async () => {
            await getBalance();
        };
        if (account) {
            void checkBalance();
        }
    }, [account]);

    const handleDelegation = async (
        validatorAddress: string,
        amount: string,
    ) => {
        console.log('Delegate to ', validatorAddress, amount.toString());
        await getBalance();

        const fee = { amount: [{ amount: '0', denom }], gas: '500000' };
        const operatorAddress = transformOperAddress(validatorAddress);
        const delegatePromise = delegateTokens(
            endpointUrl,
            signer,
            account,
            operatorAddress,
            { amount, denom },
            fee,
        );

        const options: ToastOptions = {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
                background: 'var(--objDefault)',
                color: 'var(--faceDefault)',
            },
        };

        await toast.promise(
            delegatePromise,
            {
                pending: 'Delegating...',
                success: 'Delegation Success',
                error: 'Delegation Failed',
            },
            options,
        );

        await getBalance();
    };

    return (
        <div className='app'>
            <QueryClientProvider client={queryClient}>
                <ToastContainer
                    position='top-center'
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Router>
                    <Header />
                    <div
                        css={{
                            margin: '0 auto',
                            padding: '1rem 0',
                            width: '920px',
                            '> div': {
                                margin: '2rem 0',
                            },
                        }}
                    >
                        <Route exact path='/'>
                            <Validators
                                onDelegate={handleDelegation}
                                maxBalance={balance}
                            />
                        </Route>
                        <Route path='/governance'>
                            <Proposals />
                        </Route>
                        {/* <Route path='/cellars'>
                            <Cellars />
                        </Route> */}
                    </div>
                    <Footer />
                </Router>
            </QueryClientProvider>
        </div>
    );
}

export default App;
